import GilroyWOFF from "../assets/fonts/Gilroy.woff";
import GilroyBoldWOFF from "../assets/fonts/Gilroy-Bold.woff";
import GilroyExtraBoldWOFF from "../assets/fonts/Gilroy-ExtraBold.woff";
import PoppinLightWOFF from "../assets/fonts/Gilroy-Light.woff";
import GilroyMediumWOFF from "../assets/fonts/Gilroy-Medium.woff";

const gilroyMedium = {
  fontFamily: "Gilroy-Medium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Gilroy'),
		local('Gilroy-Medium'),
		url(${GilroyMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroyBold = {
  fontFamily: "Gilroy-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Gilroy'),
		local('Gilroy-Bold'),
		url(${GilroyBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroyExtraBold = {
  fontFamily: "Gilroy-ExtraBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Gilroy'),
		local('Gilroy-ExtraBold'),
		url(${GilroyExtraBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroyLight = {
  fontFamily: "Gilroy-Light",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Gilroy'),
		local('Gilroy-Light'),
		url(${PoppinLightWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const gilroy = {
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Gilroy'),
		local('Gilroy-Regular'),
		url(${GilroyWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [gilroy, gilroyMedium, gilroyBold, gilroyExtraBold, gilroyLight];

export default fonts;
