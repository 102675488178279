import { Box, Link, useMediaQuery } from "@material-ui/core";

import "./footer.scss";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Discord } from "../../assets/images//discord.svg";
import { ReactComponent as Github } from "../../assets/images/github.svg";
import { ReactComponent as Medium } from "../../assets/images/medium.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";

export default function Footer() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  return (
    <div className="footer font-14 color2 font-weight-5">
      <Box display="flex" justifyContent="center" className="footer-docs">
        <div className="">
          <Link href="https://github.com/pinnakoex/pinnakoes_contract" target={"_blank"} className="">
            <Github className="" />
          </Link>
        </div>
        <div className="ml-32">
          <Link href="https://twitter.com/PinnakoDex" target={"_blank"} className="">
            <Twitter className="" />
          </Link>
        </div>
        <div className="ml-32">
          <Link href="https://discord.com/invite/pinnakodex" target={"_blank"} className="">
            <Discord className="" />
          </Link>
        </div>
        <div className="ml-32">
          <Link href="https://t.me/pinnakodex" target={"_blank"} className="">
            <Telegram className="" />
          </Link>
        </div>
        <div className="ml-32">
          <Link href="https://medium.com/@Pinnako" target={"_blank"} className="">
            <Medium className="" />
          </Link>
        </div>
      </Box>
      <Box display="flex" justifyContent="center" className="footer-docs2">
        <div className="">
          <Link
            href="https://pinnoka-markets.gitbook.io/pinnako_io/overview/introduction"
            target={"_blank"}
            className=""
          >
            Documentation
          </Link>
        </div>
        <div className="ml-18">
          <Link href="https://github.com/pinnakoex/pinnakoes_contract" target={"_blank"} className="">
            Github
          </Link>
        </div>
        <div className="ml-18">
          <Link href="" target={"_blank"} className="">
            Media kit
          </Link>
        </div>
      </Box>
    </div>
  );
}
