import { Box, Link, useMediaQuery, Drawer, SvgIcon } from "@material-ui/core";

import "./header.scss";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as Menu } from "../../assets/images/menu.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Discord } from "../../assets/images//discord.svg";
import { ReactComponent as Github } from "../../assets/images/github.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { LaunchBtn } from "../Button/LaunchBtn";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header">
      <Box display="flex" alignItems="center" justifyContent="space-between" className="header-view">
        <Box display="flex" alignItems="center">
          <Link component={NavLink} to="/" className="logo">
            <Logo />
          </Link>
          {/* {isVerySmallScreen && (
            <div className="ml-12" onClick={() => setIsOpen(true)}>
              <Menu />
            </div>
          )} */}
        </Box>
        <Box display="flex" alignItems="center">
          {!isVerySmallScreen && (
            <Box display="flex" alignItems="center" className="navList font-14 font-weight-b">
              <div className="navItem">
                <a href="" target={"_blank"} className="color1">
                  Ecosystem
                </a>
              </div>
              <div className="navItem">
                <a
                  href="https://pinnoka-markets.gitbook.io/pinnako_io/overview/introduction"
                  target={"_blank"}
                  className="color1"
                >
                  Docs
                </a>
              </div>
            </Box>
          )}
          <div className="launchBtn">
            <a href="https://app.pinnako.io/" target="_blank">
              <LaunchBtn>Launch APP</LaunchBtn>
            </a>
          </div>
        </Box>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <Box display="flex" flexDirection="column" alignItems="flex-end" className="drawer-view">
          <div className="close mt-30">
            <Close onClick={() => setIsOpen(false)} />
          </div>
          <Box display="flex" flexDirection="column" alignItems="flex-end" className="menu-list mt-20 font-20"></Box>
        </Box>
      </Drawer>
    </div>
  );
}
