import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import useTheme from "./hooks/useTheme";
import { light as lightTheme } from "./themes/light.js";

import { Box } from "@material-ui/core";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LoadingSplash from "./components/LoadingSplash";
import "./style.scss";

AOS.init();

const DEBUG = false;
const Home = lazy(() => import("./views/home"));

// const blockExplorer = targetNetwork.blockExplorer;

function App() {
  const [theme, mounted] = useTheme();
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  let themeMode = lightTheme;

  const [show, setShow] = useState(false);

  return (
    <ThemeProvider theme={themeMode}>
      <CssBaseline />
      <Suspense fallback={<LoadingSplash />}>
        <Box display="flex" flexDirection="column" className={`app-container`}>
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/">
              <Redirect to="/" />
            </Route>
          </Switch>
          <Footer />
        </Box>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
