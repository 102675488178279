import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const LaunchBtn = styled(Button)({
  height: "48px",
  lineHeight: "48px",
  maxHeight: "48px",
  width: "144px",
  color: "#100032",
  fontSize: 16,
  fontFamily: "Gilroy-Bold",
  textTransform: "initial",
  background: "#14D1B2",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#2AEBCB",
    color: "#FFFFFF",
  },
});
